<script setup lang="ts">
const { t } = useI18n();
const { count, clearCompareList } = useLocalCompareList();
const compareSidebarController = useProductCompareSidebarController();

const controller = useModal();

function yesClicked() {
  clearCompareList();
  controller.close();
}

function noClicked() {
  controller.close();
}

onMounted(() => {
  const body = document.body;
  body.classList.remove("overflow-hidden");

  watch(compareSidebarController.isOpen, (newValue) => {
    if (newValue) {
      body.classList.add("overflow-hidden");
    } else {
      body.classList.remove("overflow-hidden");
    }
  });
});
</script>

<template>
  <!-- TODO: remove z-1 and find out why it is hidden behind footer -->
  <div
    v-if="count > 0"
    class="z-1 select-none px-5 rd-1 -translate-x-50% bg-white b-1 b-scheppach-primary-500 fixed bottom-5 left-50%"
  >
    <p class="text-5 c-scheppach-primary-700 text-center mb-2">
      {{ t("cart.compareProduct") }}
    </p>
    <div class="flex justify-around items-center">
      <div
        class="relative c-scheppach-primary-500 hover:c-scheppach-primary-300 cursor-pointer"
        @click="compareSidebarController.toggle"
      >
        <span
          class="i-sl-synchronize-arrows-square-1 inline-block text-4xl"
        ></span>
        <span class="absolute bottom-3 right-3.5 font-700">{{ count }}</span>
      </div>

      <div
        class="i-sl-close c-scheppach-primary-500 hover:c-scheppach-error-500 cursor-pointer text-5.5"
        @click="controller.open"
      ></div>
    </div>
  </div>

  <SharedModal :controller="controller">
    <div class="p-5 w-auto sm:w-130">
      <div class="mb-7">
        {{ t("product.clearCompareList") }}
      </div>
      <div class="flex flex-col sm:flex-row gap-4">
        <button
          class="rd w-full md:w-[273px] h-[51px] bg-scheppach-primary-700 hover:bg-scheppach-primary-500 c-white uppercase"
          @click="yesClicked"
        >
          {{ t("yes") }}
        </button>
        <button
          class="rd w-full md:w-[273px] h-[51px] b b-scheppach-primary-700 bg-scheppach-shades-0 hover:bg-scheppach-primary-50 c-scheppach-primary-700 uppercase"
          @click="noClicked"
        >
          {{ t("no") }}
        </button>
      </div>
    </div>
  </SharedModal>
</template>
